import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BoardPageTemplate from '../components/BoardPageTemplate'
import { HTMLContent } from '../components/Content'

const BoardPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BoardPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        disclosure={post.frontmatter.conflictsDisclosure}
        activeConflicts={post.frontmatter.activeConflicts}
        notice={post.frontmatter.notice}
        board={post.frontmatter.board}
      />
    </Layout>
  )
}

BoardPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BoardPage

export const boardPageQuery = graphql`
  query BoardPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        notice {
          title
          message
        }
        conflictsDisclosure {
          publicURL
        }
        activeConflicts
        board {
          role
          name
          term
          bio
        }
      }
    }
  }
`
