import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Content from './Content'
import {HTMLContent} from './Content'
import {remark} from 'remark'
import remarkHtml from 'remark-html'

const BoardPageTemplate = ({ title, notice, board, content, disclosure, activeConflicts, contentComponent}) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-1">
            <div className="section">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>

              <Notice notice={notice} />

              <hr />
              {board.map(director => (
                <div key={director.name}>
                {/* <div className="card" style={{ marginBottom: '1rem'}}> */}
                  {/* <div className="card-content"> */}
                  <div className="media" style={{ marginBottom: '2rem'}}>
                    <div className="media-content">
                    <p  className="is-size-5">{director.name}<br /><small className="has-text-grey">{director.role} - <span style={{ fontSize: '0.8rem' }}>Term ends: {director.term}</span></small></p>
                    <div className="content">
                      <p>Business Phone: (713) 623-4531<br />Fax:	(713) 623-6143<br />
                      Mailing Address: 1300 Post Oak Blvd., Ste. 1400, Houston, TX 77056</p>
                      <HTMLContent content={remark().use(remarkHtml).processSync(director.bio).toString()}/>
                    </div>
                  </div>
                  </div>
                    <hr />
                  {/* </div> */}
                {/* </div> */}
                </div>
              ))}

              <h3 className="is-size-3">Conflicts Disclosure Statements:</h3>
              {(activeConflicts) 
              ? <p><a href={disclosure.publicURL} className="button">View disclosure statement(s)</a></p> 
              : <p>No conflicts to report for the current Board of Directors.</p> }

              <PageContent className="content" content={content} />

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BoardPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BoardPageTemplate
